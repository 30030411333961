export default {
  nav: {
    index: "INDEX",
    about: "ABOUT",
    business: "SERVICE",
    features: "FEATURES",
    service: "PROCEDURES",
    contact: "CONTACT",
    project: 'INCUBATION PROJECTS',
  },
  slogan: {
    title: "Bringing NFT's to Life",
    subTitle: "YOUR NFT Springboard….",
    desc: "Interested in taking advantage of the immense rise in non-fungible tokens (NFT’s) to discover new markets for your creative content?",
    subName: "We’re here to HELP….",
    content:
      "Like many…. you may have numerous unanswered questions about how to start.  Our dedicated team of blockchain specialists will guide you throughout the entire process from initial ideas to pocketing your rewards.",
  },
  explain: {
    title:
      "We will help you evaluate all the options to produce and market your content as NFT’s.",
    desc: "If you are a content creator - whether its artwork, graphic designs, 3D models, or videos - or own interesting IP  - we have the experience and technical skills to turn it all into successful NFT launches using a variety of minting and marketing platform options to fit your every need. ",
  },
  about: {
    title: "About Us",
    desc: "The technology team behind Blue Dolphin’s creation has worked on Blockchain and immersive digital technologies for over a decade helping innovators unleash new value in their business eco-systems.  With the recent global emergence of the use of Non-Fungible Tokens (NFTs) within the creative community, Blue Dolphin was created to utilize their decade long expertise to assist the creative community’s exploitation of the exceptional emerging market opportunities in NFTs and the Metaverse.",
    content:
      "Utilizing our establishing networks with global crypto exchanges and social media platforms, Blue Dolphin has the necessary comprehensive reach to support any new NFT offering.  Providing full-service market placement analysis, minting, asset and traffic management and wallet and payment services, Blue Dolphin offers content creators and IP holders comprehensive NFT consulting services across existing platforms and in the evolving metaverse.",
  },
  object: {
    title:
      "As an internationally registered corporation with offices in Asia and North America, Blue Dolphin provides services to the following content providers:",
    0: "Artists",
    1: "Musicians",
    2: "Athletes",
    3: "Entertainers",
    4: "Sports Leagues",
    5: "Fashion Industry",
    6: "Movie Industry",
    7: "Auto Industry",
    8: "Lifestyle Brands",
    9: "Character IP",
    10: "Education",
    11: "Charitable Organizations",
  },
  trade: {
    title: "Industry Exploitation",
    desc: "The core team has backgrounds of IP development, immersive experience business, digital content and crypto assets, committed to NFT research, nurturing and business models. Integrating professions of artworks, creative content, intellectual properties, blockchain with NFT businesses, the team is capable of supplying applicable consulting services.",
  },
  value: {
    title: "Valuable Resources",
    desc: "Blue Dolphin has a global scale marketing resources including artworks, collectibles, financial derivatives, IP, original musics, fashion brands and creative contents. We are able to provide our clients sustainable value-adding growth, and help them reach to potential customers/investors from another field.",
  },
  business: {
    0: {
      title: "NFT Issuing",
      desc: "We help IP owners and Content providers issue their NFTs by providing NFT design and development, NFT minting, NFT technology support, NFT asset management, NFT regulatory guidance and NFT issuing strategies.",
    },
    1: {
      title: "Creative Development",
      desc: "We assist IP owners and Content providers in not only converting their artworks to NFT assets, but to help them explore a variety of promising opportunities with 3-D NFT modeling, Multi-sensory NFTs, and Play-to-Earn NFT Games. ",
    },
    2: {
      title: "Tech Support",
      desc: "To meet the wide variety of client needs, NFT inventing and issuing schemes will be highly customized for targeted applications with our core technologies. We appreciate the often confusing array of issues related to NFT technology.  We make it a priority to be ready to answer IP owners and Content providers technical questions about NFT trading platforms, crypto technology, digital assets and the Defi ecosystem.",
    },
    3: {
      title: "Payment Support",
      desc: "We understand that of particular importance to those newly engaging in the NFT eco-system is payment support.  Blue Dolphin provides full support for digital payment processes, platforms and operations which include both pure-play digital currency payment services as well as an opportunity for fiat currency exchanges.",
    },
  },
  features: {
    0: {
      title: "Industry Development",
      desc: "Blue Dolphin’s management team are thoroughly experienced in IP development, immersive experience enterprises, digital content, crypto assets and blockchain technology.  We are committed to NFT research including developing an incubator/accelerator program to nurture new business models for the creative community. In addition to providing Content providers with full access to existing market platforms, we will ultimately build our own open standards platform to support market growth for emerging NFT opportunities.",
    },
    1: {
      title: "Join the Fun",
      desc: "We welcome all creative spirits that are interested in helping to develop the potential of this growing digital world. We not only provide existing content providers with NFT services, but also welcome creators to jointly explore with us original content creation, immersive digital experiences, cross-chain technology strategies, novel smart contracts and incubating new concepts.  These new concepts will ultimately include support for NFT Metaverse Content for the design, promotion, sale and secure storage of NFT assets across metaverses.",
    },
    2: {
      title: "The Metaverse",
      desc: "Without doubt the metaverse expands the potential horizons for creating experience-based NFTs. Over time we plan on incubating apps to embody social platforms for creative expression, entertainment, e-sport and socializing. The application of digital twins of physical environments and personalities allow for the creation of Hybrid Events where live performances can be integrated with simultaneous virtual appearance.  Ultimately the metaverse concept will provide Content creators, Brands and IP holders an entirely new realm for self-expression, further NFT development and enhanced financial rewards.",
    },
  },
  encryption: {
    title: "Crypto Climate Accord",
    0: "Blue Dolphin is a Signatory to the Crypto Climate Accord.  We fully support the Accord’s overall objective to decarbonize the global crypto industry by prioritizing climate stewardship.  The Accord has two specific objectives:",
    1: "1.)	Achieve net-zero carbon emissions from electricity consumption for CAA Signatories by 2030",
    2: "2.)	Develop standards, tools and technologies to accelerate the adoption of 100% renewably-powered blockchains by 2025 for United Nations Framework Convention on Climate Change (UNFCCC).",
  },
  form: {
    input: "Input your email address to subscribe",
    button: "Send",
  },
  formSmall: {
    input: "Your email address",
    button: "Send",
  },
  servicesTitle: "Services",
  featuresTitle: "Features",
  businessTitle: "Service Procedures",
  projects: {
    title: 'Incubation Projects',
    forkearth: {
      name: 'Fork Earth',
      desc: 'Having spent the last decade working on blockchain technology, smart city applications, crypto development and immersive interactive environments, the creators of Fork Earth have witnessed the groundbreaking potential of integrating geo-location capabilities into these technologies to create a comprehensive ecosystem. That ecosystem supports development of applications that have economic utility across the physical/virtual boundary.'
    },
    larvaverse: {
      name: 'Larvaverse',
      desc: 'Larvaverse is a metaverse based on Larva animation series, and also the frist IP+metaverse application around the globe. It not only has Larva, but also combines with immersive digital experience, inviting fans to join and co-build a DAO community. There are many Larva elements to fans to explore, and they can be reused for more creations by fans. The operational team is meant to launch and serve, but the main characters can be you who watching this content right now. Life can be boring, but Larva can make fun. Just join us now!'
    }
  }
};
