export default {
  nav: {
    index: "首页",
    about: "关于我们",
    business: "业务介绍",
    features: "机构优势",
    service: "业务逻辑",
    contact: "联系我们",
    project: '孵化项目',
  },
  slogan: {
    title: "让NFT触手可及",
    subTitle: "你的NFT起跳板",
    desc: "想要给你的创意内容添上NFT的翅膀？",
    subName: "我们恰好能帮忙",
    content:
      "像许多人一样，关于如何开始你或许还有很多未被解答的问题。我们的区块链专家团队将全程陪同你，从初始想法开始打磨一直到生成“口袋”里的数字资产。",
  },
  explain: {
    title: "我们将帮助你评估好过程当中的所有问题，以进行NFT的铸造与市场推广",
    desc: "如果你是内容创作者，无论是艺术作品、图形设计、3D建模、视频创作抑或是你拥有自己的IP，我们有足够的经验和技术支持来将这些有趣的内容转化为非常棒的NFT作品，我们的作品平台推广服务将满足你的所有需要。",
  },
  about: {
    title: "关于我们",
    desc: "蓝海豚背后的技术团队已经在区块链和沉浸式数字技术领域积累了十数年经验，以帮助所有的创新者在他们的商业生态中释放新的价值。在当今的全球创新创意业态中，由于NFT应用的兴起，蓝海豚因势而生，以期帮助想在创新创意领域大有可为的年轻人与团队。",
    content:
      "凭借在全球加密交易市场和社交媒体资源，蓝海豚有全备的服务模块来支持任一新NFT的发行。我们的服务内容包括市场分析、NFT铸造、资产管理、流量管理、钱包开发及多种支付方式支持。除此之外，蓝海豚还为内容创作者和IP拥有者提供综合的一站式NFT咨询服务，横跨多平台，同时支持元宇宙技术开发的升级与迭代。",
  },
  object: {
    title:
      "蓝海豚布局全球，同时亚洲和北美两地均设有办公室，以下内容生产者将是团队的主要服务对象：",
    0: "艺术家",
    1: "音乐人",
    2: "运动员",
    3: "艺人",
    4: "俱乐部",
    5: "时尚/时装产业",
    6: "电影产业",
    7: "汽车产业",
    8: "生活方式品牌",
    9: "个人IP",
    10: "教育",
    11: "慈善组织",
  },
  trade: {
    title: "行业深耕",
    desc: "创始团队深耕IP开发、体验式商业、数字内容、加密资产等领域，具有专注NFT领域的研究、开发与可行商业路径挖掘能力，针对NFT所融合的艺术作品、创意内容、版权、区块链各专业板块为合作伙伴提供具有商业可行性的咨询服务。",
  },
  value: {
    title: "价值资源",
    desc: "机构整合全球艺术家作品、藏品、金融衍生品、IP等资源，触及艺术画作、原创音乐、潮流品牌、创意内容等市场，为合作伙伴提供可持续优化的价值增长空间，完成NFT作为艺术品、产品、商品及优质资产的破壁与破圈。",
  },
  business: {
    0: {
      title: "NFT发行",
      desc: "我们为IP版权方和艺术与内容创作者提供一站式NFT发行服务，包括NFT设计与开发、NFT铸造、NFT技术咨询、NFT发行资产管理、NFT合规咨询、NFT发行方案规划等。",
    },
    1: {
      title: "内容开发",
      desc: "帮助IP版权方和艺术与内容创作者不仅将传统作品转化为数字内容资产，同时提供创新的数字创作可行路径与方案，包括3D NFT、多终端NFT和“玩赚”类NFT游戏，吸引更多创作者加入加密艺术与数字创作中来。",
    },
    2: {
      title: "技术支持",
      desc: "为了满足客户更广泛需求，NFT的创作和发行策略将可针对市场应用进行定制化，同时享有我们的核心技术支持。我们重视有关NFT及其技术相关的任何疑问，因此我们为IP拥有者和内容创作者提供咨询服务，任何有关NFT交易、加密技术、数字资产和金融生态的问题都将被解答。",
    },
    3: {
      title: "支付体系",
      desc: "我们知道在NFT生态中，支付将是非常重要的一环。蓝海豚在数字支付过程中为平台机构和运营方提供全力支持，其中包括纯数字货币支付服务和法币交易服务。",
    },
  },
  features: {
    0: {
      title: "行业深耕",
      desc: "蓝海豚的管理团队在IP开发、沉浸式体验商业、数字内容、加密资产和区块链技术等方面有完备的经验和能力积累。我们致力于进行可为创新创意社区带来崭新商业模式可能性的NFT孵化及加速服务，并进行相关NFT领域研究。除了为内容创作者提供进入已有市场及相关平台的渠道，我们也将最终建设自有开发标准的平台，来支持在持续发酵NFT领域的市场发展。",
    },
    1: {
      title: "创新商业",
      desc: "我们欢迎所有有兴趣挖掘数字世界潜力同时具有创新思维的伙伴们。我们不只为已有内容提供方提供NFT服务，同时欢迎未来创作者与我们共同开发原创作品，我们也将在沉浸式数字体验、跨链技术和智能合约等方面一同来助力新想法、新内容的诞生。这些原创内容和新想法将最终在NFT及元宇宙的概念体系下得以孕育、生长，与之有关的内容设计、宣传推广、营销渠道和NFT资产管理等策略将在元宇宙的世界里以全新的方式得到呈现。",
    },
    2: {
      title: "元宇宙",
      desc: "毫无疑问，元宇宙的概念刷新了人们的视野，让以体验为主导的NFT资产有了新的发展方向。随着时间的推移，我们计划开发一些嵌入社交平台的应用，一方面支持创意内容的表达，一方面为大家提供娱乐、数字活动和社交的场所。结合物理环境和个人特性的数字孪生将让物理和数字世界的“融合体验”成为可能，比如现场实景的表演将会与虚拟的表现方式同步呈现给大家。最终，元宇宙的革新将把内容创作者、品牌方和IP拥有者带入一个全新的自我表达、深度NFT开发和增强的金融创新领域。",
    },
  },
  encryption: {
    title: "加密技术环境协定",
    0: "蓝海豚是加密技术环境协定的签约组织。通过加强环保管理，我们完全支持协定对全球加密产品的碳减排要求。协定将包含以下两点：",
    1: "1）到2030年为止，在用电消耗方面达到CAA签订方认可的净零碳排放要求",
    2: "2）到2025年，在《联合国气候变化框架公约》的要求下，形成相关标准、管理工具和技术手段来加快百分之百可再生能源区块链的采用",
  },
  form: {
    input: "输入您的邮箱取得联系",
    button: "发送",
  },
  formSmall: {
    input: "输入您的邮箱取得联系",
    button: "发送",
  },
  servicesTitle: "业务介绍",
  featuresTitle: "机构优势",
  businessTitle: "业务逻辑",
  projects: {
    title: '孵化项目',
    forkearth: {
      name: '分叉地球',
      desc: '分叉地球是一个结合了区块链技术、智慧城市应用、加密程序开发、沉浸式交互体验的元宇宙项目。团队通过十数年的各相关行业经验，开发以地理位置为引导的核心技术并释放其更多商业可能性，将GPS融入复杂但有序的元宇宙场景生态中。该生态还将支持跨虚拟/现实场景的经济系统与基础设施，还原元宇宙本应有的真实体验。'
    },
    larvaverse: {
      name: '虫子元宇宙',
      desc: '虫子元宇宙是一个基于《爆笑虫子》动画剧集打造的元宇宙应用，也是全球首个IP+元宇宙应用。这里不仅仅有《爆笑虫子》，更将结合沉浸式数字体验，同时邀请粉丝进入我们的开放社区，一起来共建生态。粉丝在这里会发现许多爆笑元素，并可以重组、拼装成新的爆笑瞬间；如果运营团队是虫子元宇宙发起人，而正在阅读这段文字的你就可以是这个爆笑虫子世界的主角。生活枯燥，需要爆笑。快来加入吧！'
    }
  }
};
