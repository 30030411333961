import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
  path: '/',
  component: () => import('./../views/index.vue')
}, {
  path: '/projects',
  component: () => import('./../views/projects.vue')
}]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router