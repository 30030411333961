<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
html,body {
  font-family: "PingFang SC", "Microsoft YaHei", "黑体";
}
html,
body, h3, p {
  padding: 0;
  margin: 0;
}
body {
  background: #F5F5F4;
}
</style>
