import { createI18n } from "vue-i18n";
import en from "./langs/en";
import cn from "./langs/cn";

const i18n = createI18n({
  locale: ["zh-CN", "en-US"], // set locale
  messages: {
    "en-US": {
      ...en,
    },
    "zh-CN": {
      ...cn,
    },
  },
});

i18n.global.locale = window.localStorage.getItem("locale") || "en-US";

export default i18n;
